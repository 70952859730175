<template>
<div class="locale-editor" :class="[currentLocale]">
  <div class="debug" v-if="0">
    <pre v-if="debug">{{data}}</pre>
    <button variant="link" @click="onToggleDebug">DEBUG</button>
  </div>

  <div class="row locale-selector">
    <label class="col-sm-2 col-form-label">Language</label>
    <div class="col-sm-10">
      <select v-model="currentLocale" class="form-control">
        <option value="all">전체</option>
        <option value="base-ko">영문/국문 만</option>
        <option v-for="locale in locales" :value="locale">{{locale.toUpperCase()}}</option>
      </select>
    </div>
  </div>

  <div v-for="(locale, index) in locales" :aria-timestamp="timestamp" :class="['locale', locale]" >

    <template v-if="visible('text')">
      <div class="form-group row"><label class="col-sm-2 col-form-label">{{locale}}</label>
        <div class="col-sm-10">
          <input
            class="form-control"
            type="text"
            :name="locale"
            :placeholder="placeholder || ''"
            :readonly="readonly"
            :disabled="disabled"
            :value="data[locale]"
            @input="onChange"
            />
        </div>
      </div>
    </template>

    <template v-if="visible('textarea')">
      <div class="form-group row"><label class="col-sm-2 col-form-label">{{locale}}</label>
        <div class="col-sm-10">
          <textarea
            class="form-control"
            :rows="5"
            :name="locale"
            :placeholder="placeholder || ''"
            :readonly="readonly"
            :disabled="disabled"
            :value="data[locale]"
            @input="onChange"
            />
        </div>
      </div>
    </template>

    <template v-if="visible('editor')">
      <div class="form-group row"><label class="col-sm-2 col-form-label">{{locale}}</label>
        <div class="col-sm-10">
          <Editor :name="locale" :value="data[locale]" @change="onChange"></Editor>
        </div>
      </div>
    </template>

    <div class="hr-line-dashed" v-if="currentLocale === 'all' && index < locales.length - 1 || currentLocale === 'base-ko' && index === 0"></div>

  </div>

</div>
</template>

<script>
import Editor from "@/components/utils/Editor"

export default {
  data() {
    return {
      debug: true,
      locales: ['base', 'ko', 'jp', 'hk', 'cn'],
      currentLocale: 'base-ko',
      data: {},
      timestamp: +new Date(),
    }
  },
  mounted() {
    let data = this.initData()
    // console.log( "initData", data )
    this.$data.data = data || {}
  },
  props: {
    type: {
      type: String,
      default: 'text'
    },
    name: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    // locales: {
    //   type: Array,
    //   required: true,
    //   default: () => ['base', 'ko', 'jp', 'hk', 'cn']
    // },
    value: {
      type: [Object, String],
      default: () => {}
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value(value, oldValue) {
      this.$data.data = this.initData()
      this.$data.timestamp = +new Date()
    },
    data(value, oldValue) {
      // console.log( 'data', value, oldValue )

      this.$data.timestamp = +new Date()
    }
  },
  methods: {
    initData() {
      if (typeof this.value === 'string') {
        try {
          let json = JSON.parse(this.value)
          if (typeof json === 'object') {
            return json
          }
        }
        catch(e) {}

        return {
          'base': this.value
        }
      }

      return this.value || {}
    },

    setData( data ) {

      this.$emit("input", JSON.stringify(data))
    },

    visible(type) {
      if (this.type === type) {
        return true
      }

      return false
    },

    onToggleDebug() {
      this.$data.debug = !this.$data.debug
    },

    onChange(e) {
      let locale = e.target.name
      let value = e.target.value
      let data = this.data
      data[locale] = value

      this.setData(data)
    }
  },
  components: {
    Editor
  }
}
</script>
