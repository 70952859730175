<template>
<div class="extra-editor">
  <div class="debug" v-if="0">
    <pre v-if="debug">{{data}}</pre>
    <button variant="link" @click="onToggleDebug">DEBUG</button>
  </div>

  <div v-for="(field, index) in fields" :aria-timestamp="timestamp">

    <template v-if="visible(field, 'text')">
      <div class="form-group row"><label class="col-sm-2 col-form-label">{{field.label}}</label>
        <div class="col-sm-10">
          <input
            class="form-control"
            :type="field.secure ? 'password' : 'text'"
            :name="field.name"
            :placeholder="field.placeholder || ''"
            :readonly="!ediable(field)"
            :disabled="disable(field)"
            :value="data[field.name]"
            @input="onChange"
            />
          <span class="form-text" v-if="field.description">{{field.description}}</span>
        </div>
      </div>
    </template>

    <template v-if="visible(field, 'textarea')">
      <div class="form-group row"><label class="col-sm-2 col-form-label">{{field.label}}</label>
        <div class="col-sm-10">
          <textarea
            class="form-control"
            :rows="field.rows || 5"
            :name="field.name"
            :placeholder="field.placeholder || ''"
            :readonly="!ediable(field)"
            :disabled="disable(field)"
            :value="data[field.name]"
            @input="onChange"
            />
          <span class="form-text" v-if="field.description">{{field.description}}</span>
        </div>
      </div>
    </template>

    <template v-if="visible(field, 'json')">
      <div class="form-group row"><label class="col-sm-2 col-form-label">{{field.label}}</label>
        <div class="col-sm-10">
          <span class="json-text">{{data[field.name]}}</span>
          <span class="form-text" v-if="field.description">{{field.description}}</span>
        </div>
      </div>
    </template>

    <template v-if="visible(field, 'checkbox')">
      <div class="form-group row"><label class="col-sm-2 col-form-label">{{field.label}}</label>
        <div class="col-sm-10">
          <input-checkbox
            v-model="data[field.name]"
            :name="field.name"
            :expected="field.expected"
            :readonly="!ediable(field)"
            :disabled="disable(field)"
            :ripple="true">{{field.label}}</input-checkbox>
          <span class="form-text" v-if="field.description">{{field.description}}</span>
        </div>
      </div>
    </template>

    <template v-if="visible(field, 'radio')">
      <div class="form-group row"><label class="col-sm-2 col-form-label">{{field.label}}</label>
        <div class="col-sm-10">
          <input-radio
            v-for="(option, i) in field.options"
            v-model="data[field.name]"
            :key="i"
            :expected="option.value"
            :readonly="!ediable(field)"
            :disabled="disable(field)"
            :ripple="true">{{option.label}}</input-radio>
          <span class="form-text" v-if="field.description">{{field.description}}</span>
        </div>
      </div>
    </template>

    <template v-if="visible(field, 'select')">
      <div class="form-group row"><label class="col-sm-2 col-form-label">{{field.label}}</label>
        <div class="col-sm-10">
          <select
            class="form-control"
            v-model="data[field.name]"
            :name="field.name"
            :multiple="field.multiple && 'multiple'"
            :readonly="!ediable(field)"
            :disabled="disable(field)"
            @input="onChange">
            <option value="" v-if="field.multiple !== true">{{field.placeholder || ''}}</option>
            <option v-for="option in field.options" :value="option.value">{{option.label}}</option>
          </select>
          <span class="form-text" v-if="field.description">{{field.description}}</span>
        </div>
      </div>
    </template>

    <template v-if="visible(field, 'date')">
      <div class="form-group row"><label class="col-sm-2 col-form-label">{{field.label}}</label>
        <div class="col-sm-10">
          <datetime
            class="form-control"
            type="date"
            zone="local"
            v-model="data[field.name]"
            :name="field.name"
            :week-start="0"
            :placeholder="field.placeholder || ''"
            :readonly="!ediable(field)"
            :disabled="disable(field)"
             />
        </div>
      </div>
    </template>

    <template v-if="visible(field, 'datetime')">
      <div class="form-group row"><label class="col-sm-2 col-form-label">{{field.label}}</label>
        <div class="col-sm-10">
          <datetime
            class="form-control"
            type="datetime"
            zone="local"
            v-model="data[field.name]"
            :name="field.name"
            :week-start="0"
            :readonly="!ediable(field)"
            :disabled="disable(field)"
            />
          <span class="form-text" v-if="field.description">{{field.description}}</span>
        </div>
      </div>
    </template>

    <template v-if="visible(field, 'editor')">
      <div class="form-group row"><label class="col-sm-2 col-form-label">{{field.label}}</label>
        <div class="col-sm-10">
          <Editor v-model="data[field.name]" ></Editor>
          <span class="form-text" v-if="field.description">{{field.description}}</span>
        </div>
      </div>
    </template>

    <template v-if="visible(field, 'locale-editor')">
      <div class="form-group row"><label class="col-sm-2 col-form-label">{{field.label}}</label>
        <div class="col-sm-10">
          <LocaleEditor
            v-model="data[field.name]"
            :mode="mode"
            :name="field.name"
            :type="field.localeType"
            :locales="field.locales"
            :readonly="!ediable(field)"></LocaleEditor>
        </div>
      </div>
    </template>

    <template v-if="visible(field, 'promotion-select')">
      <div class="form-group row"><label class="col-sm-2 col-form-label">{{field.label}}</label>
        <div class="col-sm-10">
          <PromotionSelect
            v-model="data[field.name]"
            :name="field.name"
            :group="field.group"
            :readonly="!ediable(field)"
            :disabled="disable(field)"></PromotionSelect>
          <span class="form-text"></span>
        </div>
      </div>
    </template>

    <div class="hr-line-dashed" v-if="index < fields.length - 1"></div>

  </div>
</div>
</template>


<script>
import Editor from "@/components/utils/Editor"
import LocaleEditor from "@/components/utils/LocaleEditor"
import PromotionSelect from "@/components/utils/PromotionSelect"

export default {
  mounted() {
    let data = this.value || {}
    let setDefaultValue = false

    this.fields.forEach(field => {
      if (!data[field.name]) {
        data[field.name] = ''
        setDefaultValue = true
      }
    })

    if (setDefaultValue) {
      this.$data.data = data

      this.setData( data )
    }
    else {
      this.$data.data = data
    }
  },
  data() {
    return {
      debug: true,
      data: {},
      timestamp: +new Date(),
    }
  },
  props: {
    mode: {
      type: String,
      default: ''
    },
    fields: {
      type: Array,
      required: true,
      default: () => []
    },
    value: {
      type: Object,
      default: () => {}
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value(value, oldValue) {
      // console.log( 'value', value, oldValue )

      this.$data.data = value
      this.$data.timestamp = +new Date()
    },
    data(value, oldValue) {
      // console.log( 'data', value, oldValue )

      this.$emit("input", value)
      this.$data.timestamp = +new Date()
    },
  },
  methods: {
    setData( data ) {
      this.$emit("input", data)

      this.$data.timestamp = +new Date()
    },

    ediable(field) {
      return (this.mode !== 'create' && field.editable === false) || field.readonly === true ? false : true
    },

    disable(field) {
      if (field.primaryKey) {
        return true
      }

      if (field.editable == false) {
        return this.mode !== 'create'
      }

      if (typeof field.disable === 'function') {
        return field.disable(this.value)
      }

      if(field.disabledOnCreate == true && this.mode == 'create'){
        return true;
      }

      return false
    },

    visible(field, type) {
      if (field.type === type) {
        if (field.hidden === true) {
          return false
        }

        if (field.primaryKey) {
          if (this.mode === 'create') {
            return false
          }
        }

        if (field.readonly === true) {
          return this.mode !== 'create'
        }

        if(field.condition && field.condition(this.data)){
          return
        }

        return true
      }

      return false
    },

    onToggleDebug() {
      this.$data.debug = !this.$data.debug
    },

    onChange(e) {
      let name = e.target.name
      let value = e.target.value
      let data = this.data
      data[name] = value

      this.setData(data)
    }
  },
  components: {
    Editor, LocaleEditor, PromotionSelect
  }
}
</script>
