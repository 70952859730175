<template>
  <div class="editor">
    <div class="tabs-container">
      <ul class="nav nav-tabs" role="tablist">
          <li><a class="nav-link" :class="{active: mode === 'editor'}" data-toggle="tab" href="javascript:void(0);" @click="onTab('editor')">Editor</a></li>
          <li><a class="nav-link" :class="{active: mode === 'html'}" data-toggle="tab" href="javascript:void(0);" @click="onTab('html')">HTML</a></li>
      </ul>
      <div class="tab-content">
        <multipane layout="horizontal" @paneResize="onPaneResize">

          <div role="tabpanel" id="tab-1" class="tab-pane" :class="{active: mode === 'editor'}" v-if="mode === 'editor'">
            <div class="panel-body">
              <quill-editor v-model="content" :options="editorOptions" @change="onChangeHTML" ></quill-editor>
            </div>
          </div>

          <div role="tabpanel" id="tab-2" class="tab-pane" :class="{active: mode === 'html'}" v-if="mode === 'html'">
            <div class="panel-body">
              <codemirror v-model="content" :options="htmlOptions" @input="onChangeCode" ></codemirror>
            </div>
          </div>

          <multipane-resizer>
            <div class="resizer-bar"></div>
            <div class="resizer-bar"></div>
            <div class="resizer-bar"></div>
          </multipane-resizer>

          <div class="preview">

          </div>
        </multipane>
      </div>
    </div>
  </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/material.css'
import 'codemirror/theme/eclipse.css'
import 'codemirror/mode/markdown/markdown'
import 'codemirror/mode/javascript/javascript'
import 'codemirror/mode/xml/xml'

const beautify = require('beautify')

export default {
  data() {
    return {
      mode: 'editor',
      editorOptions: {
        modules: {
            toolbar: [
              // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
              [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
              // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
              // [{ 'font': [] }],
              ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
              [{'align': ''}, {'align': 'center'}, {'align': 'right'}, {'align': 'justify'}],
              ['blockquote', 'code-block'],
              [{ 'list': 'ordered'}, { 'list': 'bullet' }],
              [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
              [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
              // [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
              ['link', 'image'],
              ['clean'],                                         // remove formatting button
              [{ 'direction': 'rtl' }],                         // text direction
            ],
            history: {
              delay: 1000,
              maxStack: 50,
              userOnly: false
            }
        }
      },
      htmlOptions: {
        tabSize: 4,
        styleActiveLine: true,
        lineNumbers: true,
        lineWrapping: true,
        mode: 'text/html',
        line: true,
        theme: 'material'
      },
      content: this.value
    }
  },
  props: ['name', 'value'],
  watch: {
    content(value, oldValue) {
      this.$emit("input", value)
      this.$emit("change", {
        target: {
          name: this.name,
          value: value
        }
      })
    },
    value(value, oldValue) {
      if (value !== this.content) {
        this.$data.content = value
      }
    }
  },
  methods: {
    onTab(mode) {
      let html = this.content
      let result = beautify(html, { format: 'html' })
      this.$data.content = result
      this.$data.mode = mode
    },
    onPaneResize(e) {
      console.log( e )
    },
    onChangeHTML(e) {

    },
    onChangeCode(e) {

    }
  }
}
</script>
