<template>

<div class="resource-editor" :aria-resource-key="resourceKey">
  <div class="debug" v-if="0">
    <pre v-if="debug">{{value}}</pre>
    <button class="link" @click="onToggleDebug">DEBUG</button>
  </div>

  <div v-for="(field, index) in fields" :aria-timestamp="timestamp">
    <template v-if="field.type == 'single'">
      <legend class="col-form-label">{{field.label || field.name}}</legend>

      <div class="resource" v-for="(resource, index) in resources(field)" :aria-timestamp="timestamp">
        <div class="image" v-if="resource && (resource.resource_url || resource.resource_thumbnail_url)" >
          <img :src="resource.resource_thumbnail_url || resource.resource_url" alt="Resource Image" />
        </div>

        <div class="info" v-if="resource && resource.id" >
          <ul>
            <li><dt>ID</dt><dd>{{resource.id}}</dd></li>
            <li><dt>Natural Size</dt><dd>{{resource.natural_width}} x {{resource.natural_height}}</dd></li>
            <li><dt>FileSize</dt><dd>{{resource.filesize}}</dd></li>
            <li><dt>Status</dt><dd>{{resource.resource_status}}</dd></li>
            <li><dt>Hash</dt><dd>{{resource.resource_hash}}</dd></li>
            <li><dt>Uploaded</dt><dd>{{resource.createdAt}}</dd></li>
            <li><dt>Url</dt><dd style="width:200px">{{resource.resource_thumbnail_url || resource.resource_url}}</dd></li>
          </ul>
        </div>

        <div v-if="!readonly" class="actions">
          <div class="right">
            <button class="btn btn-danger btn-xs" @click="onClickRemove($event, resource, field)">삭제</button>
          </div>
        </div>
      </div>

      <div v-if="!readonly && resources(field).length === 0 && !sending" class="resource-actions">
        <div class="resource-action">
          <div class="custom-file">
            <label class="custom-file-label">
              <input :ref="field.name" :id="field.name" type="file" class="custom-file-input" @change="onChangeFile($event, field)" />
              <label :for="field.name" class="custom-file-label">{{filePlaceholder(field)}}</label>
            </label>
          </div>

          <button class="btn btn-success btn-xs" @click="onClickAdd($event, field)">업로드</button>
        </div>
      </div>
    </template>

    <template v-if="field.type == 'list'">
      <legend class="col-form-label">{{field.label || field.name}} ({{resources(field).length}})</legend>
      <div class="resource" v-for="(resource, index) in resources(field)" :aria-timestamp="timestamp">
        <div class="image" v-if="resource && (resource.resource_url || resource.resource_thumbnail_url)" >
          <img :src="resource.resource_thumbnail_url || resource.resource_url " alt="Resource Image" />
        </div>
        <div class="info" v-if="resource && resource.id" >
          <ul>
            <li><dt>ID</dt><dd>{{resource.id}}</dd></li>
            <li><dt>Natural Size</dt><dd>{{resource.natural_width}} x {{resource.natural_height}}</dd></li>
            <li><dt>FileSize</dt><dd>{{resource.filesize}}</dd></li>
            <li><dt>Status</dt><dd>{{resource.resource_status}}</dd></li>
            <li><dt>Hash</dt><dd>{{resource.resource_hash}}</dd></li>
            <li><dt>Uploaded</dt><dd>{{resource.createdAt}}</dd></li>
            <li><dt>Url</dt><dd style="width:200px">{{resource.resource_thumbnail_url || resource.resource_url}}</dd></li>
          </ul>
        </div>

        <div v-if="!readonly" class="actions">
          <div class="right">
            <button class="btn btn-danger btn-xs" @click="onClickRemove($event, resource, field)">삭제</button>
          </div>
        </div>
      </div>

      <div class="resource-action" v-if="!readonly && !sending">
        <div class="custom-file">
          <input :id="field.name" type="file" class="custom-file-input" @change="onChangeFile($event, field)" />
          <label :for="field.name" class="custom-file-label">{{filePlaceholder(field)}}</label>
        </div>

        <button class="btn btn-success btn-xs" @click="onClickAdd($event, field)">업로드</button>
      </div>
    </template>

    <div class="hr-line-dashed" v-if="index < fields.length - 1"></div>

  </div>

</div>
</template>


<script>
import loadImage from 'blueimp-load-image';

export default {
  data() {
    return {
      debug: false,
      sending: false,
      timestamp: +new Date(),
      uploadFiles: {}
    }
  },
  props: {
    resourceKey: {
      type: String,
      default: ''
    },
    fields: {
      type: Array,
      required: true
    },
    value: {
      type: Object,
      default: () => {}
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value(value, oldValue) {
      this.$data.timestamp = +new Date()
    }
  },
  methods: {
    resources( field ) {
      if (this.value) {
        if (field.type === 'single') {
          let resource = this.value[field.name]
          if (resource && (resource.id || resource._id)) {
            return [resource]
          }
          return []
        }
        let resources = this.value[field.name]
        return this._.filter(resources, resource => resource && (resource.id || resource._id))
      }
      return []
    },

    setResources( field, value ) {
      let data = this.value || {}
      data[field.name] = value

      this.$emit("input", data)
      this.$data.timestamp = +new Date()
    },

    filePlaceholder(field) {
      if (this.uploadFiles[field.name]) {
        let file = this.uploadFiles[field.name]
        return file.name
      }

      return 'Choose file...'
    },

    onToggleDebug() {
      this.$data.debug = !this.debug
    },

    onChangeFile(e, field) {
      this.$data.uploadFiles[field.name] = e.target.files[0]
      this.$data.timestamp = +new Date()
    },

    async onClickAdd(e, field) {
      const formData = new FormData()

      let loadingImage = this.uploadFiles[field.name]

      loadImage(
        loadingImage,
        (img, data) => {
          img.toBlob(async (blob) => {
            let imageFile = null;
            try {
              imageFile = new File([blob], loadingImage.name, { type: loadingImage.type });
            } catch (e) {
              imageFile = Object.assign(blob, loadingImage.name, { type: loadingImage.type });
            } finally {
              formData.append('file', imageFile);
            }

            formData.append('key', this.resourceKey)
            formData.append('tag', field.name)

            if(field.name === 'thumbnail_image'){
              formData.append('tWidth', 128)
              formData.append('tHeight', 128)
            }

            this.$data.sending = true

            this.$store.dispatch("resource/upload", formData)
              .then((data) => {
                if (data.resources.length > 0) {
                  let resource = data.resources[0]
                  if (field.type === 'single') {
                    this.setResources(field, resource)
                  }
                  else if (field.type === "list") {
                    let resources = (this.value || {})[field.name] || []
                    resources.push(resource)

                    this.setResources(field, resources)
                  }
                }
              })
              .catch((e) => {
                this.$toast.error(e.message)
              })
              .finally(() => {
                this.uploadFiles[field.name] = null
                this.$data.sending = false
                this.$data.timestamp = +new Date()
              })
            });
          },
          {
            orientation: true,
            canvas: true,
            maxWidth: 600,
          }
        );
    },

    onClickRemove(e, resource, field) {

      this.$data.sending = true
      this.$data.timestamp = +new Date()

      if (field.type === 'single') {
        this.setResources(field, null)
      }
      else if (field.type === "list") {
        let resources = (this.value || {})[field.name] || []
        let newResources = []

        if (resource) {
          for (let index in resources) {
            if (resources[index] && resources[index].id !== resource.id) {
              newResources.push(resources[index])
            }
          }
        }
        else {
          for (let index in resources) {
            if (resources[index]) {
              newResources.push(resources[index])
            }
          }
        }
        this.setResources(field, newResources)
      }

      setTimeout(() => {
        this.$data.uploadFiles[field.name] = null
        this.$data.sending = false
        this.$data.timestamp = +new Date()
      }, 0)
    }
  }
}
</script>
